var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-carousel',{staticClass:"bmv-carousel",attrs:{"id":"bmv-carousel","interval":5000,"controls":"","indicators":""},model:{value:(_vm.slide),callback:function ($$v) {_vm.slide=$$v},expression:"slide"}},[_c('b-carousel-slide',{attrs:{"img-src":_vm.slide1},scopedSlots:_vm._u([{key:"img",fn:function(){return [_c('div',{staticClass:"bmv-carousel-content",style:({
          'background-image': `url(${_vm.slide1})`
        })},[_c('span',{staticClass:"bmv-carousel-title-sm"},[_vm._v("Персонални функционални тренировки")]),_c('span',{staticClass:"bmv-carousel-title-lg"},[_vm._v("Дефинирай своята цел")]),_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#activities'),expression:"'#activities'"}],staticClass:"btn btn-success bmv-carousel-btn",attrs:{"href":"#","role":"button"}},[_vm._v("Повече")])])]},proxy:true}])}),_c('b-carousel-slide',{scopedSlots:_vm._u([{key:"img",fn:function(){return [_c('div',{staticClass:"bmv-carousel-content",style:({
          'background-image': `url(${_vm.slide2})`
        })},[_c('span',{staticClass:"bmv-carousel-title-sm"},[_vm._v("Запознай се с нашите класове")]),_c('span',{staticClass:"bmv-carousel-title-lg"},[_vm._v("Групови тренировки")]),_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#activities-groups'),expression:"'#activities-groups'"}],staticClass:"btn btn-success bmv-carousel-btn",attrs:{"href":"#","role":"button"}},[_vm._v("Повече")])])]},proxy:true}])}),_c('b-carousel-slide',{scopedSlots:_vm._u([{key:"img",fn:function(){return [_c('div',{staticClass:"bmv-carousel-content",style:({
          'background-image': `url(${_vm.slide3})`
        })},[_c('span',{staticClass:"bmv-carousel-title-sm"},[_vm._v("Придай смисъл на своите тренировки")]),_c('span',{staticClass:"bmv-carousel-title-lg"},[_vm._v("Присъедини се към групата")]),_c('div',{staticClass:"bmv-social-icons-pack"},[_c('a',{attrs:{"href":"https://www.facebook.com/bemovinsport"}},[_c('i',{staticClass:"fab fa-facebook-f"})]),_c('a',{attrs:{"href":"https://www.instagram.com/bemovinsport"}},[_c('i',{staticClass:"fab fa-instagram"})]),_c('a',{attrs:{"href":"https://www.youtube.com/channel/UCchRJiyYsgQl5oGdagyqk9w/featured"}},[_c('i',{staticClass:"fab fa-youtube"})])])])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }